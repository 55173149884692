import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { config } from 'config';

export default function Home() {
    const router = useRouter();

    useEffect(() => void router.push(config.routes.events), [router]);
}
